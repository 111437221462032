import React, { SyntheticEvent, useEffect } from "react";
import Wrapper from "../Wrapper";
import { Role } from "../../classes/role";
import axios from "axios";
import { User } from "../../classes/user";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const UserEdit = (props: any) => {
  const [roles, setRoles] = React.useState([]);
  const [first_name, setFirstName] = React.useState("");
  const [last_name, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role_id, setRoleId] = React.useState(0);
  const [redirect, setRedirect] = React.useState(false);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get("roles")
      .then((response) => {
        setRoles(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`users/${id}`)
      .then((reponse) => {
        const user: User = reponse.data.data;

        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
        setRoleId(user.role.id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    await axios
      .put(`users/${id}`, {
        first_name,
        last_name,
        email,
        role_id,
      })
      .then((response) => {
        setRedirect(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  if (redirect) {
    return <Navigate to={"/users"} />;
  }

  return (
    <Wrapper>
      <form onSubmit={submit} className="my-md-4">
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            className="form-control"
            name="first_name"
            defaultValue={first_name}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            className="form-control"
            name="last_name"
            defaultValue={last_name}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="text"
            className="form-control"
            name="email"
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Role</label>
          <select
            name="role_id"
            className="form-control"
            value={role_id}
            onChange={(e) => {
              setRoleId(parseInt(e.target.value));
            }}
          >
            <option>Select Role</option>
            {roles.map((role: Role) => {
              return (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              );
            })}
          </select>
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default UserEdit;

import React, { SyntheticEvent } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const ImageCreate = () => {
  const [name, setName] = React.useState("");
  const [image, setImage] = React.useState(null);
  const [redirect, setRedirect] = React.useState(false);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const data = new FormData();
    data.append("image", image as any);
    data.append("name", name);

    // console.log(image);

    await axios
      .post("images", data)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });

    setRedirect(true);
  };

  const handleOnChange = (e: any) => {
    const file = e.target.files[0];
    console.log("file", file);

    const file_name = String(file.name).replace(/\.[^/.]+$/, ""); // Remove file extension
    setName(file_name);
    setImage(e.target.files[0]);
  };

  return redirect ? (
    <Navigate to={"/images"} />
  ) : (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Image</label>
          <div className="input-group">
            <div className="input-group-append">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default ImageCreate;

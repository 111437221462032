import React from "react";
import Wrapper from "../Wrapper";
import { Link } from "react-router-dom";
import axios from "axios";
import { Category } from "../../classes/category";
import Paginator from "../components/Paginator";
import Deleter from "../components/Deleter";
import { connect } from "react-redux";
import { User } from "../../classes/user";
import { toast } from "react-hot-toast";
import Switches from "../components/ui/switches";
import { convertImageURL } from "../../libs/image";

const Categories = (props: { user: User }) => {
  const [categories, setCategories] = React.useState([]);
  const [lastPage, setLastPage] = React.useState(0);
  const page = 1;

  React.useEffect(() => {
    (async () => {
      // const response = await axios.get(`categories?page=${page}`);

      // setCategories(response.data.data);
      // setLastPage(response.data.meta.last_page);

      await axios
        .get(`categories?page=${page}`)
        .then((response) => {
          setCategories(response.data.data);
          setLastPage(response.data.meta.last_page);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.error);
        });
    })();
  }, []);

  const handleDelete = async (id: number) => {
    setCategories(categories.filter((p: Category) => p.id !== id));
  };

  const handlePageChange = async (page: number) => {
    const response = await axios.get(`categories?page=${page}`);

    setCategories(response.data.data);
  };

  const actions = (id: number) => {
    if (props.user.canEdit("categories")) {
      return (
        <div className="btn-group mr-2">
          <Link
            to={`/categories/${id}/edit`}
            className="btn btn-sm btn-outline-secondary"
          >
            Edit
          </Link>
          <Deleter
            id={id}
            endpoint={"categories"}
            handleDelete={handleDelete}
          />
        </div>
      );
    }
  };

  let addButton = null;

  if (props.user.canEdit("categories")) {
    addButton = (
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0">
          <Link
            to={"/categories/create"}
            className="btn btn-sm btn-outline-secondary"
          >
            Add
          </Link>
        </div>
      </div>
    );
  }

  const onChangeActive = (e: any) => {
    const dataid = e.target.getAttribute("data-dataid");

    // const id = e.target.id;
    const id = dataid;
    const checked = e.target.checked;

    axios
      .put(`/categories/${id}`, {
        is_active: checked,
      })
      .then((response) => {
        toast.success("Category updated successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  return (
    <Wrapper>
      {addButton}

      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Name</th>
              <th>Label</th>
              <th>Description</th>
              <th>Slug</th>
              <th>Active</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category: Category) => {
              return (
                <tr key={category.id}>
                  <td>{category.id}</td>
                  <td>
                    <img
                      src={convertImageURL(category.image)}
                      alt=""
                      width="50"
                    />
                  </td>
                  <td>{category.name}</td>
                  <td>{category.label}</td>
                  <td>{category.description}</td>
                  <td>{category.slug}</td>
                  <td>
                    <Switches
                      id={`active-${category.id}`}
                      dataid={category.id}
                      active={category.is_active}
                      handleChange={onChangeActive}
                    />
                  </td>
                  <td>{actions(category.id)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Paginator lastPage={lastPage} handlePageChange={handlePageChange} />
    </Wrapper>
  );
};

// @ts-ignore
export default connect((state) => ({ user: state.user }))(Categories);

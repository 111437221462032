import React, { SyntheticEvent, useEffect, useRef } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
// import { Navigate } from "react-router-dom";
import { Product } from "../../classes/product";
import { useParams } from "react-router-dom";
import { Category } from "../../classes/category";
import { toast } from "react-hot-toast";
import EditorComponent from "../components/editor/EditorComponent";

const ProductEdit = (props: any) => {
  const [code, setCode] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [category_id, setCategoryId] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [image, setImage] = React.useState("");
  const [sub_images, setSubImages] = React.useState([] as any[]);
  const [sub_images_text, setSubImagesText] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [content, setContent] = React.useState({});
  // const [redirect, setRedirect] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const { id } = useParams();
  const description_ejInstance: any = useRef(); // Editor instance: content
  const content_ejInstance: any = useRef(); // Editor instance: content

  useEffect(() => {
    axios
      .get(`products/${id}`)
      .then((response) => {
        const product: Product = response.data.data;
        setCategoryId(product.category_id);
        setCode(product.code);
        setTitle(product.title);
        setDescription(product.description);
        setImage(product.image);
        setSubImages(product.sub_images);
        setSlug(product.slug);
        setPrice(product.price);
        setContent(product.content);
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sub_images !== null && sub_images.length > 0) {
      const sub_images_text = sub_images.join(",\n");
      setSubImagesText(sub_images_text);
    }
  }, [sub_images]);

  useEffect(() => {
    (async () => {
      await axios
        .get(`categories`)
        .then((response) => {
          setCategories(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.error);
        });
    })();
  }, []);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const export_description = await description_ejInstance.current.save();
    const export_content = await content_ejInstance.current.save();

    await axios
      .put(`products/${id}`, {
        category_id,
        code,
        title,
        description: export_description,
        image,
        sub_images,
        slug,
        price,
        content: export_content,
      })
      .then((response) => {
        console.log("response", response);
        toast.success("Product updated successfully");
        // setRedirect(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  // if (redirect) {
  //   return <Navigate to={"/products"} />;
  // }

  return (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Code</label>
          <input
            type="text"
            className="form-control"
            name="code"
            defaultValue={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            name="title"
            defaultValue={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Category</label>
          <select
            className="form-control"
            name="category_id"
            onChange={(e) => setCategoryId(Number(e.target.value))}
            value={category_id}
          >
            {categories.map((category: Category) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.id + " - " + category.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form-group">
          <label>Image</label>
          <input
            type="text"
            className="form-control"
            name="image"
            defaultValue={image}
            onChange={(e) => setImage(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Sub Images</label>
          <textarea
            className="form-control"
            name="sub_image"
            rows={3}
            defaultValue={sub_images_text}
            onChange={(e) => {
              setSubImages(e.target.value.split(","));
            }}
          ></textarea>
        </div>
        <button className="btn btn-outline-secondary">Save</button>
        <br />
        <br />

        <div className="form-group">
          <label>Slug</label>
          <input
            type="text"
            className="form-control"
            name="slug"
            defaultValue={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Price</label>
          <input
            type="number"
            className="form-control"
            name="price"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <div className="form-editor">
            <EditorComponent
              ekey="product-description"
              spacetext={description}
              ref={description_ejInstance}
            />
          </div>
        </div>

        <button className="btn btn-outline-secondary">Save</button>
        <br />
        <br />

        <div className="form-group">
          <label>Content</label>
          <div className="form-editor">
            <EditorComponent
              ekey="product-content"
              spacetext={content}
              ref={content_ejInstance}
            />
          </div>
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default ProductEdit;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import configureStore from "./redux/configureStore";
import { Provider } from "react-redux";
import ToastProvider from "./providers/toast-provider";

let baseURL = "http://localhost:8000/api/admin";
if (process.env.REACT_APP_BACKEND_URL) {
  baseURL = process.env.REACT_APP_BACKEND_URL + "/api/admin";
}

axios.defaults.baseURL = baseURL;
axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
  "token"
)}`;

const store = configureStore();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ToastProvider />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

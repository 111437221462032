import React, { SyntheticEvent, useRef } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Navigate } from "react-router-dom";
// import ImageUpload from "../components/ImageUpload";
import { toast } from "react-hot-toast";
import EditorComponent from "../components/editor/EditorComponent";

const CategoryCreate = () => {
  const [name, setName] = React.useState("");
  const [label, setLabel] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [content, setContent] = React.useState(null);
  const [image, setImage] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);
  const ejInstance: any = useRef(); // Editor instance: content

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const export_content = await ejInstance.current.save();

    await axios
      .post("categories", {
        name,
        label,
        description,
        image,
        slug,
        content: export_content,
      })
      .then((res) => {
        console.log(res);
        toast.success("Category created successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });

    setRedirect(true);
  };

  // const imageChanged = (image: string) => {
  //   setImage(image);
  // };

  // if (redirect) {
  //   return <Navigate to="/categories" />;
  // }

  return (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Label (Billboard)</label>
          <input
            type="text"
            className="form-control"
            name="label"
            onChange={(e) => setLabel(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            className="form-control"
            name="description"
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Image</label>
          <input
            type="text"
            className="form-control"
            name="image"
            onChange={(e) => setImage(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Slug</label>
          <input
            className="form-control"
            name="email"
            onChange={(e) => setSlug(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Content</label>
          <div className="form-editor">
            <EditorComponent
              ekey="category-content"
              spacetext={content}
              ref={ejInstance}
            />
          </div>
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default CategoryCreate;

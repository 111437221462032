import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
class Deleter extends Component<{
  id: number;
  endpoint: string;
  handleDelete: any;
}> {
  delete = async () => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      // await axios.delete(`${this.props.endpoint}/${this.props.id}`);
      // this.props.handleDelete(this.props.id);

      await axios
        .delete(`${this.props.endpoint}/${this.props.id}`)
        .then((response) => {
          this.props.handleDelete(this.props.id);
          toast.success("Delete completed.");
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.error);
        });
    }
  };

  render() {
    return (
      <div
        className="btn btn-sm btn-outline-secondary"
        onClick={() => this.delete()}
      >
        Delete
      </div>
    );
  }
}

export default Deleter;

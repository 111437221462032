import React, { SyntheticEvent, useEffect, useRef } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Navigate } from "react-router-dom";
// import ImageUpload from "../components/ImageUpload";
import { Category } from "../../classes/category";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import EditorComponent from "../components/editor/EditorComponent";

const CategoryEdit = (props: any) => {
  const [name, setName] = React.useState("");
  const [label, setLabel] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [content, setContent] = React.useState({});
  const [image, setImage] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);
  const ejInstance: any = useRef(); // Editor instance: content

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`categories/${id}`)
      .then((response) => {
        const category: Category = response.data.data;
        setName(category.name);
        setLabel(category.label);
        setDescription(category.description);
        setImage(category.image);
        setSlug(category.slug);
        setContent(category.content);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const export_content = await ejInstance.current.save();

    console.log(export_content);

    await axios
      .put(`categories/${id}`, {
        name,
        label,
        description,
        image,
        slug,
        content: export_content,
      })
      .then((response) => {
        console.log("response", response);
        setRedirect(true);
        toast.success("Category updated successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  // const imageChanged = (image: string) => {
  //   setImage(image);
  // };

  // if (redirect) {
  //   return <Navigate to="/categories" />;
  // }

  return (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Label (Billboard)</label>
          <input
            type="text"
            className="form-control"
            name="label"
            defaultValue={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            className="form-control"
            name="description"
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Image</label>
          {/* <ImageUpload value={image} imageChanged={imageChanged} /> */}
          <input
            type="text"
            className="form-control"
            name="image"
            defaultValue={image}
            onChange={(e) => setImage(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Slug</label>
          <input
            className="form-control"
            name="slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Content</label>
          <div className="form-editor">
            <EditorComponent
              ekey="category-content"
              spacetext={content}
              ref={ejInstance}
            />
          </div>
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default CategoryEdit;

import React, { Component } from "react";
import Wrapper from "../Wrapper";
import c3 from "c3";
import axios from "axios";
import { toast } from "react-hot-toast";

const formatter = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

class Dashboard extends Component {
  componentDidMount = async () => {
    let chart = c3.generate({
      bindto: "#chart",
      data: {
        x: "x",
        columns: [["x"], ["Sales"]],
        types: {
          Sales: "bar",
        },
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            format: "%Y-%m-%d",
          },
        },
        y: {
          tick: {
            format: function (d: any) {
              return formatter.format(Number(d));
            },
          },
        },
      },
    });

    // const response = await axios.get("chart");

    // const records: { date: string; sum: number }[] = response.data.data;

    // chart.load({
    //   columns: [
    //     ["x", ...records.map((r) => r.date)],
    //     ["Sales", ...records.map((r) => r.sum)],
    //   ],
    // });

    await axios
      .get("chart")
      .then((response) => {
        const records: { date: string; sum: number }[] = response.data.data;
        console.log("records", records);
        chart.load({
          columns: [
            ["x", ...records.map((r) => r.date)],
            ["Sales", ...records.map((r) => r.sum)],
          ],
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  render() {
    return (
      <Wrapper>
        <h2>Daily Sales</h2>

        <div id="chart" />
      </Wrapper>
    );
  }
}

export default Dashboard;

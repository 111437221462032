import React from "react";
import Wrapper from "../Wrapper";
import { Link } from "react-router-dom";
import axios from "axios";
import { Product } from "../../classes/product";
import Paginator from "../components/Paginator";
import Deleter from "../components/Deleter";
import { connect } from "react-redux";
import { User } from "../../classes/user";
import { toast } from "react-hot-toast";
import Switches from "../components/ui/switches";
import Checkbox from "../components/ui/checkbox";
import Search from "../components/Search";
import { convertImageURL } from "../../libs/image";

const Products = (props: { user: User }) => {
  const [products, setProducts] = React.useState([]);
  const [lastPage, setLastPage] = React.useState(0);
  const page = 1;
  const SEARCH_ENDPOINT = "products";

  React.useEffect(() => {
    (async () => {
      // const response = await axios.get(`products?page=${page}`);

      // setProducts(response.data.data);
      // setLastPage(response.data.meta.last_page);

      await axios
        .get(`products?page=${page}`)
        .then((response) => {
          setProducts(response.data.data);
          setLastPage(response.data.meta.last_page);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.error);
        });
    })();
  }, []);

  const handleDelete = async (id: number) => {
    setProducts(products.filter((p: Product) => p.id !== id));
  };

  const handlePageChange = async (page: number) => {
    // const response = await axios.get(`products?page=${page}`);

    // setProducts(response.data.data);

    await axios
      .get(`products?page=${page}`)
      .then((response) => {
        setProducts(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  const actions = (id: number) => {
    if (props.user.canEdit("products")) {
      return (
        <div className="btn-group mr-2">
          <Link
            to={`/products/${id}/edit`}
            className="btn btn-sm btn-outline-secondary"
          >
            Edit
          </Link>
          <Deleter id={id} endpoint={"products"} handleDelete={handleDelete} />
        </div>
      );
    }
  };

  const getImage = (image: string) => {
    if (String(image).includes("http")) {
      return image;
    } else {
      return process.env.REACT_APP_BACKEND_URL + "/" + image;
    }
  };

  const onChangeActive = (e: any) => {
    const dataid = e.target.getAttribute("data-dataid");

    // const id = e.target.id;
    const id = dataid;
    const checked = e.target.checked;

    axios
      .put(`/products/${id}`, {
        is_active: checked,
      })
      .then((response) => {
        toast.success("Product updated successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  const onChangeFeature = (e: any) => {
    const dataid = e.target.getAttribute("data-dataid");

    // const id = e.target.id;
    const id = dataid;
    const checked = e.target.checked;

    axios
      .put(`/products/${id}`, {
        is_feature: checked,
      })
      .then((response) => {
        toast.success("Product updated successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  let addButton = null;

  if (props.user.canEdit("products")) {
    addButton = (
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0">
          <Link
            to={"/products/create"}
            className="btn btn-sm btn-outline-secondary"
          >
            Add
          </Link>
        </div>
      </div>
    );
  }

  return (
    <Wrapper>
      <div className="float-left">{addButton}</div>
      <div className="float-right mt-3">
        <Search endpoint={SEARCH_ENDPOINT} setData={setProducts} />
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Category</th>
              <th>Feature</th>
              <th>Code</th>
              <th>Title</th>
              <th>Price</th>
              <th>Slug</th>
              <th>Active</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product: Product) => {
              return (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>
                    <img
                      alt=""
                      src={convertImageURL(product.image)}
                      width="50"
                    />
                  </td>
                  <td>{product.category_id}</td>
                  <td>
                    <Checkbox
                      id={`feature-${product.id}`}
                      dataid={product.id}
                      active={product.is_feature}
                      handleChange={onChangeFeature}
                    />
                  </td>
                  <td>{product.code}</td>
                  <td>{product.title}</td>
                  <td>{product.price}</td>
                  <td>{product.slug}</td>
                  <td>
                    <Switches
                      id={`active-${product.id}`}
                      dataid={product.id}
                      active={product.is_active}
                      handleChange={onChangeActive}
                    />
                  </td>

                  <td>{actions(product.id)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Paginator lastPage={lastPage} handlePageChange={handlePageChange} />
    </Wrapper>
  );
};

// @ts-ignore
export default connect((state) => ({ user: state.user }))(Products);

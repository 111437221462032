import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { User } from "../../classes/user";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-hot-toast";

class Nav extends Component<{ user: User }> {
  state = {
    redirect: false,
  };

  handleClick = async () => {
    await axios
      .post("logout", {})
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });

    this.setState({
      redirect: true,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={"/login"} />;
    }

    return (
      <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <button
          type="button"
          className="btn btn-link navbar-brand col-md-3 col-lg-2 mr-0 px-3"
        >
          Company name
        </button>

        <ul className="my-2 my-md-0 mr-md-3">
          <Link to={"/profile"} className="p-2 text-white">
            {this.props.user.name}
          </Link>
          <button
            className="btn btn-link p-2 text-white"
            onClick={this.handleClick}
          >
            Sign out
          </button>
        </ul>
      </nav>
    );
  }
}

// @ts-ignore
export default connect((state) => ({ user: state.user }))(Nav);

import React, { SyntheticEvent, useEffect } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Review } from "../../classes/review";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const ReviewEdit = (props: any) => {
  const [name, setName] = React.useState("");
  const [score, setScore] = React.useState(4);
  const [content, setContent] = React.useState("");
  const [avatar, setAvatar] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`reviews/${id}`)
      .then((response) => {
        const review: Review = response.data.data;

        setName(review.name);
        setScore(review.score);
        setContent(review.content);
        setAvatar(review.avatar);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    await axios
      .put(`reviews/${id}`, {
        name,
        score,
        content,
        avatar,
      })
      .then((response) => {
        setRedirect(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  // const avatarChanged = (avatar: string) => {
  //   setAvatar(avatar);
  // };

  return redirect ? (
    <Navigate to={"/reviews"} />
  ) : (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Score (Billboard)</label>
          <input
            type="number"
            className="form-control"
            name="label"
            defaultValue={score}
            onChange={(e) => setScore(parseInt(e.target.value))}
          />
        </div>
        <div className="form-group">
          <label>Content</label>
          <textarea
            className="form-control"
            name="content"
            defaultValue={content}
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Image</label>
          {/* <ImageUpload value={avatar} avatarChanged={avatarChanged} /> */}
          <input
            type="text"
            className="form-control"
            name="avatar"
            defaultValue={avatar}
            onChange={(e) => setAvatar(e.target.value)}
          />
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default ReviewEdit;

export class Image {
  id: number;
  name: string;
  url: string;
  file_size: number;

  constructor(id = 0, name = "", url = "", file_size = 0) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.file_size = file_size;
  }

  static formatFileSize(file_size: number) {
    if (file_size < 1024) {
      return file_size + " B";
    } else if (file_size < 1024 * 1024) {
      return (file_size / 1024).toFixed(2) + " KB";
    }
    return (file_size / (1024 * 1024)).toFixed(2) + " MB";
  }
}

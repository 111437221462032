import React from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Order } from "../../classes/order";
import { OrderItem } from "../../classes/order_item";
import { useParams } from "react-router-dom";
import Currency from "../components/ui/currency";

const OrderItems = (props: any) => {
  const [order_items, setOrderItems] = React.useState([]) as any;
  const { id } = useParams();

  React.useEffect(() => {
    axios
      .get(`orders/${id}`)
      .then((response) => {
        const order: Order = response.data.data;
        console.log("order", order);

        setOrderItems(order.order_items);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <Wrapper>
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Product Title</th>
              <th>Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {order_items.map((order_item: OrderItem) => {
              return (
                <tr key={order_item.id}>
                  <td>{order_item.id}</td>
                  <td>{order_item.product_title}</td>
                  <td>
                    <Currency value={order_item.price} />
                  </td>
                  <td>{order_item.quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

export default OrderItems;

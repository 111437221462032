import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Link } from "react-router-dom";
import Paginator from "../components/Paginator";
import { toast } from "react-hot-toast";
import LongParagraph from "../components/ui/long-paragraph";
import { Contact } from "../../classes/contact";

const Contacts = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    axios
      .get(`contacts?page=${page}`)
      .then((response) => {
        const parsedContacts = Contact.fromJsonList(response.data.data);

        setContacts(parsedContacts);

        setLastPage(response.data.meta.last_page);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  }, []);

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  const handleExport = async () => {
    await axios
      .get("export", { responseType: "blob" })
      .then((response) => {
        // const blob = new Blob([response.data], { type: "text/csv" });
        const downloadUrl = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "contacts.csv";
        link.click();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  return (
    <Wrapper>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 bcontact-bottom">
        <div className="btn-toolbar mb-2 mb-md-0">
          <button
            onClick={handleExport}
            className="btn btn-link btn-sm btn-outline-secondary"
          >
            Export
          </button>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Subject</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Message</th>
              <th>IPAddress</th>
              <th>Platform</th>
              <th>Referrer</th>
              <th>Device</th>
              <th>Target</th>
              <th>Keyword</th>
              <th>MatchType</th>
              <th>Placement</th>
              <th>UtmID</th>
              <th>UtmTerm</th>
              <th>UtmMedium</th>
              <th>Utmsource</th>
              <th>Utmcampaign</th>
              <th>IPStatus</th>
              <th>IPCountry</th>
              <th>IPCountryCode</th>
              <th>IPRegion</th>
              <th>IPRegionName</th>
              <th>IPCity</th>
              <th>IPZip</th>
              <th>IPLat</th>
              <th>IPLon</th>
              <th>IPTimezone</th>
              <th>IPIsp</th>
              <th>IPOrg</th>
              <th>IPAs</th>
              <th>IPQuery</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact: Contact) => {
              return (
                <tr key={contact.id}>
                  <td>{contact.id}</td>
                  <td>{contact.subject}</td>
                  <td>{contact.name}</td>
                  <td>{contact.email}</td>
                  <td>{contact.phone}</td>
                  <td>
                    <div className="width_200">
                      <LongParagraph data={contact.message} />
                    </div>
                  </td>
                  <td>{contact.ip_address}</td>
                  <td>{contact.platform}</td>
                  <td>{contact.referrer}</td>
                  <td>{contact.device}</td>
                  <td>{contact.target}</td>
                  <td>{contact.keyword}</td>
                  <td>{contact.match_type}</td>
                  <td>{contact.placement}</td>
                  <td>{contact.utm_id}</td>
                  <td>{contact.utm_term}</td>
                  <td>{contact.utm_medium}</td>
                  <td>{contact.utm_source}</td>
                  <td>{contact.utm_campaign}</td>
                  <td>{contact.ip_status}</td>
                  <td>{contact.ip_country}</td>
                  <td>{contact.ip_countryCode}</td>
                  <td>{contact.ip_region}</td>
                  <td>{contact.ip_regionName}</td>
                  <td>{contact.ip_city}</td>
                  <td>{contact.ip_zip}</td>
                  <td>{contact.ip_lat}</td>
                  <td>{contact.ip_lon}</td>
                  <td>{contact.ip_timezone}</td>
                  <td>
                    <div className="width_200">
                      <LongParagraph data={contact.ip_org} />
                    </div>
                  </td>
                  <td>
                    <div className="width_200">
                      <LongParagraph data={contact.ip_as} />
                    </div>
                  </td>
                  <td>{contact.ip_as}</td>
                  <td>{contact.ip_query}</td>
                  <td>
                    <div className="btn-group mr-2">
                      <Link
                        to={`/contacts/${contact.id}`}
                        className="btn btn-sm btn-outline-secondary"
                      >
                        View
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Paginator lastPage={lastPage} handlePageChange={handlePageChange} />
    </Wrapper>
  );
};

export default Contacts;

import React, { SyntheticEvent, useEffect } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Navigate } from "react-router-dom";
// import ImageUpload from "../components/ImageUpload";
import { Image } from "../../classes/image";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const ImageEdit = (props: any) => {
  const [name, setName] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`images/${id}`)
      .then((response) => {
        const image: Image = response.data.data;

        setName(image.name);
        setUrl(image.url);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    await axios
      .put(`images/${id}`, {
        name,
        url,
      })
      .then((response) => {
        setRedirect(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  return redirect ? (
    <Navigate to={"/images"} />
  ) : (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="form-group">
          <img
            alt=""
            src={process.env.REACT_APP_BACKEND_URL + "/" + url}
            width="150"
          />
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default ImageEdit;

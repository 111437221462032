import React, { SyntheticEvent } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const ReviewCreate = () => {
  const [name, setName] = React.useState("");
  const [score, setScore] = React.useState(4);
  const [content, setContent] = React.useState("");
  const [avatar, setAvatar] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    await axios
      .post("reviews", {
        name,
        score,
        content,
        avatar,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });

    setRedirect(true);
  };

  // const avatarChanged = (avatar: string) => {
  //   setImage(Avatar);
  // };

  return redirect ? (
    <Navigate to={"/reviews"} />
  ) : (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Label (Billboard)</label>
          <input
            type="text"
            className="form-control"
            name="score"
            onChange={(e) => setScore(parseInt(e.target.value))}
          />
        </div>
        <div className="form-group">
          <label>Content</label>
          <textarea
            className="form-control"
            name="content"
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Image</label>
          <input
            type="text"
            className="form-control"
            name="avatar"
            onChange={(e) => setAvatar(e.target.value)}
          />
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default ReviewCreate;

import React, { useEffect } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Table from "@editorjs/table";
import Checklist from "@editorjs/checklist";
import Quote from "@editorjs/quote";
import NestedList from "@editorjs/nested-list";
import Embed from "@editorjs/embed";
// import Marker from "@editorjs/marker";
import InlineCode from "@editorjs/inline-code";
import InlineImage from "editorjs-inline-image";
import RawTool from "@editorjs/raw";
import ColorPlugin from "editorjs-text-color-plugin";

const DEFAULT_INITIAL_DATA = {
  time: new Date().getTime(),
  blocks: [
    {
      type: "header",
      data: {
        text: "Heading",
        level: 5,
      },
    },
    {
      type: "paragraph",
      data: {
        text: `Body`,
      },
    },
  ],
};

function EditorComponent(props, ref) {
  // const ref = useRef();
  const { ekey, spacetext } = props;

  const initEditor = () => {
    // console.log("spacetext", spacetext);

    const editor = new EditorJS({
      holder: `editorjs-${ekey}`,
      onReady: () => {
        ref.current = editor;
      },
      autofocus: true,
      data: spacetext ? spacetext : DEFAULT_INITIAL_DATA,
      onChange: async () => {
        // console.log("editor", editor);
        // await editor.save();
      },
      tools: {
        header: Header,
        quote: Quote,
        table: Table,
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        embed: {
          class: Embed,
          inlineToolbar: true,
          config: {
            services: {
              facebook: true,
              instagram: true,
              youtube: true,
              vimeo: true,
              coub: true,
            },
          },
        },
        // Marker: {
        //   class: Marker,
        //   shortcut: "CMD+SHIFT+M",
        // },
        inlineCode: {
          class: InlineCode,
          shortcut: "CMD+SHIFT+M",
        },
        image: {
          class: InlineImage,
          inlineToolbar: true,
          config: {
            embed: {
              display: true,
            },
            unsplash: {
              appName: "your_app_name",
              clientId: "your_client_id",
            },
          },
        },
        raw: RawTool,
        Color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            colorCollections: [
              "#EC7878",
              "#9C27B0",
              "#673AB7",
              "#3F51B5",
              "#0070FF",
              "#03A9F4",
              "#00BCD4",
              "#4CAF50",
              "#8BC34A",
              "#CDDC39",
              "#FFF",
            ],
            defaultColor: "#FF1300",
            type: "text",
            customPicker: true, // add a button to allow selecting any colour
          },
        },
        Marker: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            defaultColor: "#FFBF00",
            type: "marker",
            icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`,
          },
        },
      },
    });
  };

  useEffect(() => {
    // This will run only once
    // initEditor();

    if (ref.current === null) {
      initEditor();
    }
    return () => {
      ref?.current?.destroy();
      ref.current = null;
    };
  }, [spacetext]);

  return (
    <>
      <div id={`editorjs-${ekey}`}></div>
    </>
  );
}

export default React.forwardRef(EditorComponent);

import React from "react";
import "./App.css";
import Dashboard from "./secure/dashboard/Dashboard";
import Users from "./secure/users/Users";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./public/Login";
import Register from "./public/Register";
import RedirectToDashboard from "./secure/RedirectToDashboard";
import UserCreate from "./secure/users/UserCreate";
import UserEdit from "./secure/users/UserEdit";
import Roles from "./secure/roles/Roles";
import RoleCreate from "./secure/roles/RoleCreate";
import RoleEdit from "./secure/roles/RoleEdit";
import Categories from "./secure/categories/Categories";
import CategoryCreate from "./secure/categories/CategoryCreate";
import CategoryEdit from "./secure/categories/CategoryEdit";
import Products from "./secure/products/Products";
import ProductCreate from "./secure/products/ProductCreate";
import ProductEdit from "./secure/products/ProductEdit";
import Orders from "./secure/orders/Orders";
import OrderItems from "./secure/orders/OrderItems";
import Profile from "./secure/profile/Profile";
import Images from "./secure/images/Images";
import ImageCreate from "./secure/images/ImageCreate";
import ImageEdit from "./secure/images/ImageEdit";
import Reviews from "./secure/reviews/Reviews";
import ReviewCreate from "./secure/reviews/ReviewCreate";
import ReviewEdit from "./secure/reviews/ReviewEdit";
import Faqs from "./secure/faqs/Faqs";
import FaqCreate from "./secure/faqs/FaqCreate";
import FaqEdit from "./secure/faqs/FaqEdit";
import Contacts from "./secure/contacts/Contacts";
import Posts from "./secure/posts/Posts";
import PostCreate from "./secure/posts/PostCreate";
import PostEdit from "./secure/posts/PostEdit";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<RedirectToDashboard />} />
          <Route path={"/dashboard"} element={<Dashboard />} />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/register"} element={<Register />} />
          <Route path={"/users"} element={<Users />} />
          <Route path={"/users/create"} element={<UserCreate />} />
          <Route path={"/users/:id/edit"} element={<UserEdit />} />
          <Route path={"/roles"} element={<Roles />} />
          <Route path={"/roles/create"} element={<RoleCreate />} />
          <Route path={"/roles/:id/edit"} element={<RoleEdit />} />
          <Route path={"/images"} element={<Images />} />
          <Route path={"/images/create"} element={<ImageCreate />} />
          <Route path={"/images/:id/edit"} element={<ImageEdit />} />
          <Route path={"/categories"} element={<Categories />} />
          <Route path={"/categories/create"} element={<CategoryCreate />} />
          <Route path={"/categories/:id/edit"} element={<CategoryEdit />} />
          <Route path={"/products"} element={<Products />} />
          <Route path={"/products/create"} element={<ProductCreate />} />
          <Route path={"/products/:id/edit"} element={<ProductEdit />} />
          <Route path={"/posts"} element={<Posts />} />
          <Route path={"/posts/create"} element={<PostCreate />} />
          <Route path={"/posts/:id/edit"} element={<PostEdit />} />
          <Route path={"/orders"} element={<Orders />} />
          <Route path={"/orders/:id"} element={<OrderItems />} />
          <Route path={"/reviews"} element={<Reviews />} />
          <Route path={"/reviews/create"} element={<ReviewCreate />} />
          <Route path={"/reviews/:id/edit"} element={<ReviewEdit />} />
          <Route path={"/faqs"} element={<Faqs />} />
          <Route path={"/faqs/create"} element={<FaqCreate />} />
          <Route path={"/faqs/:id/edit"} element={<FaqEdit />} />
          <Route path={"/contacts"} element={<Contacts />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
import Wrapper from "../Wrapper";
import { Link } from "react-router-dom";
import axios from "axios";
import { Image } from "../../classes/image";
import Paginator from "../components/Paginator";
import Deleter from "../components/Deleter";
import { connect } from "react-redux";
import { User } from "../../classes/user";
import { toast } from "react-hot-toast";
import Search from "../components/Search";

const Images = (props: { user: User }) => {
  const [images, setImages] = React.useState([]);
  const [lastPage, setLastPage] = React.useState(0);
  const page = 1;
  const SEARCH_ENDPOINT = "images";

  React.useEffect(() => {
    (async () => {
      // const response = await axios.get(`images?page=${page}`);

      // setImages(response.data.data);
      // setLastPage(response.data.meta.last_page);

      await axios
        .get(`images?page=${page}`)
        .then((response) => {
          setImages(response.data.data);
          setLastPage(response.data.meta.last_page);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.error);
        });
    })();
  }, []);

  const handleDelete = async (id: number) => {
    setImages(images.filter((p: Image) => p.id !== id));
  };

  const handlePageChange = async (page: number) => {
    // const response = await axios.get(`images?page=${page}`);

    // setImages(response.data.data);

    await axios
      .get(`images?page=${page}`)
      .then((response) => {
        setImages(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  const actions = (id: number) => {
    if (props.user.canEdit("images")) {
      return (
        <div className="btn-group mr-2">
          <Link
            to={`/images/${id}/edit`}
            className="btn btn-sm btn-outline-secondary"
          >
            Edit
          </Link>
          <Deleter id={id} endpoint={"images"} handleDelete={handleDelete} />
        </div>
      );
    }
  };

  let addButton = null;

  if (props.user.canEdit("images")) {
    addButton = (
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0">
          <Link
            to={"/images/create"}
            className="btn btn-sm btn-outline-secondary"
          >
            Add
          </Link>
        </div>
      </div>
    );
  }

  return (
    <Wrapper>
      <div className="float-left">{addButton}</div>
      <div className="float-right mt-3">
        <Search endpoint={SEARCH_ENDPOINT} setData={setImages} />
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Name</th>
              <th>Url</th>
              <th>File Size</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {images.map((image: Image) => {
              return (
                <tr key={image.id}>
                  <td>{image.id}</td>
                  <td>
                    <img
                      alt={image.name}
                      src={process.env.REACT_APP_BACKEND_URL + "/" + image.url}
                      width="50"
                    />
                  </td>
                  <td>{image.name}</td>
                  <td>{image.url}</td>
                  <td>{Image.formatFileSize(image.file_size)}</td>
                  <td>{actions(image.id)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Paginator lastPage={lastPage} handlePageChange={handlePageChange} />
    </Wrapper>
  );
};

// @ts-ignore
export default connect((state) => ({ user: state.user }))(Images);

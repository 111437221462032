import React from "react";
import Wrapper from "../Wrapper";
import { Link } from "react-router-dom";
import axios from "axios";
import { Faq } from "../../classes/faq";
import Paginator from "../components/Paginator";
import Deleter from "../components/Deleter";
import { connect } from "react-redux";
import { User } from "../../classes/user";
import { toast } from "react-hot-toast";

const Faqs = (props: { user: User }) => {
  const [faqs, setFaqs] = React.useState([]);
  const [lastPage, setLastPage] = React.useState(0);
  const page = 1;

  React.useEffect(() => {
    (async () => {
      // const response = await axios.get(`faqs?page=${page}`);

      // setFaqs(response.data.data);
      // setLastPage(response.data.meta.last_page);

      await axios
        .get(`faqs?page=${page}`)
        .then((response) => {
          setFaqs(response.data.data);
          setLastPage(response.data.meta.last_page);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.error);
        });
    })();
  }, []);

  const handleDelete = async (id: number) => {
    setFaqs(faqs.filter((p: Faq) => p.id !== id));
  };

  const handlePageChange = async (page: number) => {
    const response = await axios.get(`faqs?page=${page}`);

    setFaqs(response.data.data);
  };

  const actions = (id: number) => {
    if (props.user.canEdit("faqs")) {
      return (
        <div className="btn-group mr-2">
          <Link
            to={`/faqs/${id}/edit`}
            className="btn btn-sm btn-outline-secondary"
          >
            Edit
          </Link>
          <Deleter id={id} endpoint={"faqs"} handleDelete={handleDelete} />
        </div>
      );
    }
  };

  let addButton = null;

  if (props.user.canEdit("faqs")) {
    addButton = (
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0">
          <Link
            to={"/faqs/create"}
            className="btn btn-sm btn-outline-secondary"
          >
            Add
          </Link>
        </div>
      </div>
    );
  }

  return (
    <Wrapper>
      {addButton}

      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Question</th>
              <th>Answer</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {faqs.map((faq: Faq) => {
              return (
                <tr key={faq.id}>
                  <td>{faq.id}</td>
                  <td>{faq.question}</td>
                  <td>{faq.answer}</td>
                  <td>{actions(faq.id)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Paginator lastPage={lastPage} handlePageChange={handlePageChange} />
    </Wrapper>
  );
};

// @ts-ignore
export default connect((state) => ({ user: state.user }))(Faqs);

import React, { SyntheticEvent } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Permission } from "../../classes/permission";
import { Navigate } from "react-router-dom";
import { Role } from "../../classes/role";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const RoleEdit = (props: any) => {
  const [name, setName] = React.useState("");
  const [selected, setSelected] = React.useState([]) as any;
  const [permissions, setPermissions] = React.useState([]) as any;
  const [redirect, setRedirect] = React.useState(false);

  const selectedRef = React.useRef(selected);
  const nameRef = React.useRef(name);
  const { id } = useParams();

  React.useLayoutEffect(() => {
    axios.get("permissions").then((response) => {
      setPermissions(response.data.data);
    });

    axios.get(`roles/${id}`).then((response) => {
      const role: Role = response.data.data;

      selectedRef.current = role.permissions.map((p: Permission) => p.id);

      setName(role.name);
      setSelected(selectedRef.current);
    });
  }, [id]);

  const check = (id: number) => {
    if (isChecked(id)) {
      selectedRef.current = selectedRef.current.filter((s: number) => s !== id);
      return;
    }

    selectedRef.current.push(id);
  };

  const isChecked = (id: number) => {
    return selectedRef.current.filter((s: number) => s === id).length > 0;
  };

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    await axios
      .put(`roles/${id}`, {
        name: nameRef.current,
        permissions: selectedRef.current,
      })
      .then((response) => {
        setRedirect(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  if (redirect) {
    return <Navigate to={"/roles"} />;
  }

  return (
    <Wrapper>
      <form onSubmit={submit} className="my-md-4">
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label">
            Name
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              name="name"
              id="name"
              defaultValue={(nameRef.current = name)}
              onChange={(e) => (nameRef.current = e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Permissions</label>
          <div className="col-sm-10">
            {permissions.map((p: Permission) => {
              return (
                <div className="form-check form-check-inline col-3" key={p.id}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={p.id}
                    // defaultChecked={isChecked(p.id)} //error: component will not rerender when useRef changed.
                    defaultChecked={selected[p.id]}
                    onChange={(e) => check(p.id)}
                  />
                  <label className="form-check-label">{p.name}</label>
                </div>
              );
            })}
          </div>
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default RoleEdit;

import React, { SyntheticEvent, useEffect, useRef } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
// import { Navigate } from "react-router-dom";
import { Post } from "../../classes/post";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import EditorComponent from "../components/editor/EditorComponent";

const PostEdit = (props: any) => {
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [image, setImage] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [content, setContent] = React.useState({});
  // const [redirect, setRedirect] = React.useState(false);
  const { id } = useParams();
  const ejInstance: any = useRef(); // Editor instance: content

  useEffect(() => {
    axios
      .get(`posts/${id}`)
      .then((response) => {
        const post: Post = response.data.data;
        setTitle(post.title);
        setDescription(post.description);
        setImage(post.image);
        setSlug(post.slug);
        setContent(post.content);
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const export_content = await ejInstance.current.save();

    console.log("export_content", export_content);

    await axios
      .put(`posts/${id}`, {
        title,
        description,
        image,
        slug,
        content: export_content,
      })
      .then((response) => {
        console.log("response", response);
        toast.success("Post updated successfully");
        // setRedirect(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  // if (redirect) {
  //   return <Navigate to={"/posts"} />;
  // }

  return (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            name="title"
            defaultValue={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            className="form-control"
            name="description"
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Image</label>
          <input
            type="text"
            className="form-control"
            name="image"
            defaultValue={image}
            onChange={(e) => setImage(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Slug</label>
          <input
            type="text"
            className="form-control"
            name="slug"
            defaultValue={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Content</label>
          <div className="form-editor">
            <EditorComponent
              ekey="post-content"
              spacetext={content}
              ref={ejInstance}
            />
          </div>
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default PostEdit;

import axios from "axios";
import { useEffect, useState } from "react";

const Search = ({ endpoint, setData }: any) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      await axios
        .get(`${endpoint}?s=${searchTerm}`)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });

      // Send Axios request here
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <>
      <div className="form-group has-search">
        <span className="fa fa-search form-control-feedback"></span>
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          autoFocus
          autoComplete="off"
          onChange={(e) => setSearchTerm(e.target.value)}
        ></input>
      </div>
    </>
  );
};

export default Search;

import { useState } from "react";

const LongParagraph = ({ data }: any) => {
  const [hidden, setHidden] = useState(true);

  let shortText = "";
  if (typeof data === "string") {
    shortText = data.substring(0, 32);
  }

  if (hidden) {
    return (
      <p className="text-lg text-gray-700">
        {shortText}
        <button
          onClick={() => setHidden(false)}
          className="text-blue-500 hover:underline"
        >
          more
        </button>
      </p>
    );
  }

  return (
    <p className="text-lg text-gray-700">
      {data}
      <button
        onClick={() => setHidden(true)}
        className="text-blue-500 hover:underline"
      >
        less
      </button>
    </p>
  );
};

export default LongParagraph;

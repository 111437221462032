import React, { SyntheticEvent, useEffect } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Faq } from "../../classes/faq";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const FaqEdit = (props: any) => {
  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`faqs/${id}`)
      .then((response) => {
        const faq: Faq = response.data.data;

        setQuestion(faq.question);
        setAnswer(faq.answer);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    await axios
      .put(`faqs/${id}`, {
        question,
        answer,
      })
      .then((response) => {
        setRedirect(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  // const imageChanged = (image: string) => {
  //   setImage(image);
  // };

  return redirect ? (
    <Navigate to={"/faqs"} />
  ) : (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="question"
            defaultValue={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Answer</label>
          <input
            type="text"
            className="form-control"
            name="answer"
            defaultValue={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default FaqEdit;

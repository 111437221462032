import React, { SyntheticEvent } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Navigate } from "react-router-dom";
// import ImageUpload from "../components/ImageUpload";
import { toast } from "react-hot-toast";

const FaqCreate = () => {
  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    await axios
      .post("faqs", {
        question,
        answer,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });

    setRedirect(true);
  };

  // const imageChanged = (image: string) => {
  //   setImage(image);
  // };

  return redirect ? (
    <Navigate to={"/faqs"} />
  ) : (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Question</label>
          <textarea
            className="form-control"
            name="question"
            onChange={(e) => setQuestion(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Answer</label>
          <input
            className="form-control"
            name="answer"
            onChange={(e) => setAnswer(e.target.value)}
          />
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default FaqCreate;

const Checkbox = ({ id, dataid, active, handleChange }: any) => {
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={id}
          data-dataid={dataid}
          defaultChecked={Boolean(active)}
          onChange={handleChange}
        ></input>
        <label className="form-check-label" htmlFor="defaultCheck1"></label>
      </div>
    </>
  );
};

export default Checkbox;

import React, { SyntheticEvent, useEffect, useRef } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
// import { Navigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import EditorComponent from "../components/editor/EditorComponent";

const PostCreate = () => {
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [image, setImage] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [content, setContent] = React.useState(null);
  // const [redirect, setRedirect] = React.useState(false);
  const ejInstance: any = useRef(); // Editor instance: content

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const export_content = await ejInstance.current.save();
    console.log("export_content", export_content);

    await axios
      .post("posts", {
        title,
        description,
        content: export_content,
        image,
        slug,
      })
      .then((res) => {
        console.log(res);
        toast.success("Post created successfully!");
        // setRedirect(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  // if (redirect) {
  //   return <Navigate to={"/posts"} />;
  // }

  return (
    <Wrapper>
      <form onSubmit={submit} className="py-md-4">
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            name="title"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            className="form-control"
            name="description"
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Image</label>
          <input
            type="text"
            className="form-control"
            name="image"
            onChange={(e) => setImage(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Slug</label>
          <input
            type="text"
            className="form-control"
            name="slug"
            onChange={(e) => setSlug(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Content</label>
          <div className="form-editor">
            <EditorComponent
              ekey="post-content"
              spacetext={content}
              ref={ejInstance}
            />
          </div>
        </div>

        <button className="btn btn-outline-secondary">Save</button>
      </form>
    </Wrapper>
  );
};

export default PostCreate;

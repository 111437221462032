import { IpInfoInterface } from "./common/ip-info";
import { TrackingInterface } from "./common/tracking";
import { OrderItem } from "./order_item";

const tracking_init = {
  url: {
    device: "",
    target: "",
    keyword: "",
    match_type: "",
    placement: "",
    utm_id: "",
    utm_term: "",
    utm_medium: "",
    utm_source: "",
    utm_campaign: "",
  },
  platform: "",
  referrer: "",
};

const ip_info_init = {
  status: "",
  country: "",
  countryCode: "",
  region: "",
  regionName: "",
  city: "",
  zip: "",
  lat: 0,
  lon: 0,
  timezone: "",
  isp: "",
  org: "",
  as: "",
  query: "",
};

export class Order {
  id: number;
  created_at: string;
  code: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  message: string;
  ip_address: string;
  ip_info: IpInfoInterface;
  tracking: TrackingInterface;
  platform: string;
  referrer: string;
  device: string;
  target: string;
  keyword: string;
  match_type: string;
  placement: string;
  utm_id: string;
  utm_term: string;
  utm_medium: string;
  utm_source: string;
  utm_campaign: string;
  total: number;
  order_items: OrderItem[];
  ip_status: string;
  ip_country: string;
  ip_countryCode: string;
  ip_region: string;
  ip_regionName: string;
  ip_city: string;
  ip_zip: string;
  ip_lat: number;
  ip_lon: number;
  ip_timezone: string;
  ip_isp: string;
  ip_org: string;
  ip_as: string;
  ip_query: string;

  constructor(
    id = 0,
    created_at = "",
    code = "",
    name = "",
    email = "",
    phone = "",
    address = "",
    city = "",
    message = "",
    ip_address = "",
    ip_info = ip_info_init,
    tracking = tracking_init,
    total = 0,
    order_items = []
  ) {
    this.id = id;
    this.created_at = new Date(created_at).toLocaleString();
    this.code = code;
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.address = address;
    this.city = city;
    this.message = message;
    this.ip_address = ip_address;
    this.ip_info = ip_info;

    this.tracking = tracking;
    this.platform = tracking?.platform || "";
    this.referrer = tracking?.referrer || "";
    this.device = tracking?.url?.device || "";
    this.target = tracking?.url?.target || "";
    this.keyword = tracking?.url?.keyword || "";
    this.match_type = tracking?.url?.match_type || "";
    this.placement = tracking?.url?.placement || "";
    this.utm_id = tracking?.url?.utm_id || "";
    this.utm_term = tracking?.url?.utm_term || "";
    this.utm_medium = tracking?.url?.utm_medium || "";
    this.utm_source = tracking?.url?.utm_source || "";
    this.utm_campaign = tracking?.url?.utm_campaign || "";
    this.total = total;
    this.order_items = order_items;

    if (ip_info) {
      this.ip_status = ip_info.status || "";
    } else {
      this.ip_status = "";
    }

    if (ip_info && ip_info.status === "success") {
      this.ip_country = ip_info.country || "";
      this.ip_countryCode = ip_info.countryCode || "";
      this.ip_region = ip_info.region || "";
      this.ip_regionName = ip_info.regionName || "";
      this.ip_city = ip_info.city || "";
      this.ip_zip = ip_info.zip || "";
      this.ip_lat = ip_info.lat || 0;
      this.ip_lon = ip_info.lon || 0;
      this.ip_timezone = ip_info.timezone || "";
      this.ip_isp = ip_info.isp || "";
      this.ip_org = ip_info.org || "";
      this.ip_as = ip_info.as || "";
      this.ip_query = ip_info.query || "";
    } else {
      this.ip_country = "";
      this.ip_countryCode = "";
      this.ip_region = "";
      this.ip_regionName = "";
      this.ip_city = "";
      this.ip_zip = "";
      this.ip_lat = 0;
      this.ip_lon = 0;
      this.ip_timezone = "";
      this.ip_isp = "";
      this.ip_org = "";
      this.ip_as = "";
      this.ip_query = "";
    }
  }

  static fromJson(data: any) {
    return new Order(
      data.id,
      data.created_at,
      data.code,
      data.name,
      data.email,
      data.phone,
      data.address,
      data.city,
      data.message,
      data.ip_address,
      data.ip_info,
      data.tracking,
      data.total,
      data.order_items
    );
  }

  static fromJsonList(data: any[]) {
    return data.map((item) => Order.fromJson(item));
  }
}

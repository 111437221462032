import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Link } from "react-router-dom";
import { Order } from "../../classes/order";
import Paginator from "../components/Paginator";
import { toast } from "react-hot-toast";
import Currency from "../components/ui/currency";
import LongParagraph from "../components/ui/long-paragraph";

const Orders = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    axios
      .get(`orders?page=${page}`)
      .then((response) => {
        const parsedOrders = Order.fromJsonList(response.data.data);

        setOrders(parsedOrders);

        setLastPage(response.data.meta.last_page);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  }, []);

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  const handleExport = async () => {
    await axios
      .get("export", { responseType: "blob" })
      .then((response) => {
        // const blob = new Blob([response.data], { type: "text/csv" });
        const downloadUrl = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "orders.csv";
        link.click();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  return (
    <Wrapper>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="btn-toolbar mb-2 mb-md-0">
          <button
            onClick={handleExport}
            className="btn btn-link btn-sm btn-outline-secondary"
          >
            Export
          </button>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Created At</th>
              <th>Code</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Total</th>
              <th>Address</th>
              <th>City</th>
              <th>Message</th>
              <th>IP Address</th>
              <th>Platform</th>
              <th>Referrer</th>
              <th>Device</th>
              <th>Target</th>
              <th>Keyword</th>
              <th>Match Type</th>
              <th>Placement</th>
              <th>Utm ID</th>
              <th>Utm Term</th>
              <th>Utm Medium</th>
              <th>Utm source</th>
              <th>Utm campaign</th>
              <th>IPStatus</th>
              <th>IPCountry</th>
              <th>IPCountryCode</th>
              <th>IPRegion</th>
              <th>IPRegionName</th>
              <th>IPCity</th>
              <th>IPZip</th>
              <th>IPLat</th>
              <th>IPLon</th>
              <th>IPTimezone</th>
              <th>IPIsp</th>
              <th>IPOrg</th>
              <th>IPAs</th>
              <th>IPQuery</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order: Order) => {
              return (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>
                    <div className="width_170">{order.created_at}</div>
                  </td>
                  <td>{order.code}</td>
                  <td>{order.name}</td>
                  <td>{order.email}</td>
                  <td>{order.phone}</td>
                  <td>
                    <Currency value={order.total} />
                  </td>
                  <td>
                    <div className="width_200">
                      <LongParagraph data={order.address} />
                    </div>
                  </td>
                  <td>{order.city}</td>
                  <td>
                    <div className="width_200">
                      <LongParagraph data={order.message} />
                    </div>
                  </td>
                  <td>{order.ip_address}</td>
                  <td>{order.platform}</td>
                  <td>{order.referrer}</td>
                  <td>{order.device}</td>
                  <td>{order.target}</td>
                  <td>{order.keyword}</td>
                  <td>{order.match_type}</td>
                  <td>{order.placement}</td>
                  <td>{order.utm_id}</td>
                  <td>{order.utm_term}</td>
                  <td>{order.utm_medium}</td>
                  <td>{order.utm_source}</td>
                  <td>{order.utm_campaign}</td>
                  <td>{order.ip_status}</td>
                  <td>{order.ip_country}</td>
                  <td>{order.ip_countryCode}</td>
                  <td>{order.ip_region}</td>
                  <td>{order.ip_regionName}</td>
                  <td>{order.ip_city}</td>
                  <td>{order.ip_zip}</td>
                  <td>{order.ip_lat}</td>
                  <td>{order.ip_lon}</td>
                  <td>{order.ip_timezone}</td>
                  <td>{order.ip_isp}</td>
                  <td>
                    <div className="width_200">
                      <LongParagraph data={order.ip_org} />
                    </div>
                  </td>
                  <td>
                    <div className="width_200">
                      <LongParagraph data={order.ip_as} />
                    </div>
                  </td>
                  <td>{order.ip_query}</td>

                  <td>
                    <div className="btn-group mr-2">
                      <Link
                        to={`/orders/${order.id}`}
                        className="btn btn-sm btn-outline-secondary"
                      >
                        View
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Paginator lastPage={lastPage} handlePageChange={handlePageChange} />
    </Wrapper>
  );
};

export default Orders;

import React from "react";
import { Navigate } from "react-router-dom";

const RedirectToDashboard = () => {
  // if (true) {
  // }

  return <Navigate to={"/dashboard"} />;
};

export default RedirectToDashboard;
